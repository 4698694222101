import React, { useState, FC } from "react";
import { useLocation } from "@reach/router";

// Styles
import {
  Wrapper,
  Item,
  SearchButton,
  Bars
} from "./styles"

// Scripts
import {
  toggleNav,
  toggleSearch
} from "../../../static/scripts/global";

// Components
import ToggleButton from "../../Buttons/ToggleButton";
import Navigation from "../../Navigation";
import SearchField from "../../SearchField";
import Button from "../../Buttons/Button";

// Images
import PhoneIcon from "../../../static/images/phone-icon.png";
import SearchIcon from "../../../static/images/search-icon.png";
import { useAgentUrlOnShopLinks } from "../../../hooks/useAgentUrlOnShopLinks";

export interface Props {
  headerData?: any,
  pageBase?: String,
  agentPage?: boolean
  pageName: string
}

const HeaderRight:FC<Props> = ({ headerData, pageBase, agentPage=false, pageName }) => {

  const location = useLocation();
  const medicarePage = pageBase === 'Medicare' || location.pathname.includes("medicare");
  const url = useAgentUrlOnShopLinks({url: "https://shop.healthmarkets.com/"});

  return (
    <Wrapper className="header-right">
      {!agentPage &&
        <Item className="hide-at-mobile">
          <a className="find-agent" href="https://www.healthmarkets.com/local-health-insurance-agent" data-gtm-id={`${pageName}-Header-AgentSearchBtn`}>Find a licensed insurance agent</a>
        </Item>
      }
      <Item className="hide-at-mobile">
        <a className="shop-now" data-gtm-id={`${pageName}-Header-ShopBtn`} aria-label="shop-now" href={ medicarePage ? "https://healthmarkets6.destinationrx.com/PC/2025/Shopping/Home" : url}>Shop plans now</a>
      </Item>
      <Item style={{display: 'flex'}}>
        <a className="hide-at-desktop" href="tel:+18178134562" data-gtm-id={`${pageName}-Header-CallBtn`}>
          <img className="mobile phone icon" src={PhoneIcon} alt="telephone icon" width='23' height='23' />
          Call Now
        </a>
        <SearchButton className="" onClick={toggleSearch}>
          <img className="icon" src={SearchIcon} alt="magnifying glass icon" width='23' height='23' />
        </SearchButton>
        <ToggleButton className="menu-button" callback={toggleNav}>
          <Bars />
        </ToggleButton>
        <Navigation headerData={headerData} pageName={pageName}/>
        <SearchField />
      </Item>
    </Wrapper>
  )
}

export default HeaderRight;

